.modal-blocked{
  position: static !important;
  overflow: auto !important;  
}
.message-dialog {
  width: 20.5rem;
}


.flyoutModal-container {
    display: flex;
    flex-wrap: nowrap;
    .Link_Huge {
        border: 1px solid transparent !important;
        padding: 0 !important;
    }
  }
  .flyoutModal-container > div {
    width: 100%;
    margin: 0rem;
    text-align: left;
  }
  .flyoutModal-container > div:first-child {
    width: 7.5rem;
    text-align: center;
  }
  .flyoutModal-container > div:nth-child(2) {
    width: 5rem;
    margin-top: 2.1rem;
    margin-left: 3rem;
  }
  .continue_button {
    .Button-ds {
      color: #000;
      background: #FFF;
      border: 1px solid #000;
      padding: 0.75rem 1.5rem;
      height: auto !important;
    }
    text-align: center;
    cursor: pointer;
  }
  .message-dialog {button{
    margin-right: 1rem;
}
p{
    margin: 1.5rem 2rem 0rem 0rem !important;
}}
.Button-ds {
  border-radius: var(--button-radius);
}